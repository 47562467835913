






































import { Vue, Component } from 'vue-property-decorator'
import ffButton from '@/components/public/Button.vue'
import { Toast } from 'vant'
import { namespace } from 'vuex-class'
import { addGA } from '../utils'
const appStore = namespace('app')
@Component({
  components: {
    ffButton
  }
})
export default class Rewards extends Vue {
  @appStore.State(state => state.transify) private transify: any
  @appStore.State(state => state.feedbackOpen) private feedbackOpen: boolean
  @appStore.State(state => state.gameLogin) private gameLogin: boolean
  gobug(): void {
    addGA('go_bug', 'click')
    if (this.feedbackOpen && this.gameLogin) {
      this['$router'].push({ path: '/feedback' }).catch(() => {
        return
      })
    }
    if (!this.gameLogin) {
      Toast(this.transify['ADV_DOWNLOAD_REPORTBUG_HINT'])
    }
  }
}
